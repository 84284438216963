@import "variables";

.sectionHead {
  display: grid;
  grid-gap: 2rem;
  h2 {
    font-size: 3.3rem;
  }
  p {
    max-width: 32rem;
    color: #777;
    font-size: 0.9rem;
  }
  margin-bottom: 2rem;
}
.jobHistory {
  padding: 3rem 1rem;
  .jobs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    .item {
      border-radius: 0.5rem;
      padding: 2rem;
      background: #f9f9ff;
      cursor: pointer;
      .head {
        display: grid;
        grid-template-columns: 1fr max-content;
        margin-bottom: 1.25rem;
        & > span {
          font-size: 0.9rem;
          color: #777;
        }
        .buttonAnim {
          grid-row: 1/3;
          grid-column: 2/3;
          padding: 1rem 1.5rem;
          .anim {
            letter-spacing: 0;
          }
          &.hover {
            background: white;
            .anim {
              span {
                color: #17a2b8;
              }
            }
          }
        }
      }
      transition: color 0.3s, background 0.3s ease-in-out;
      p {
        font-size: 0.95rem;
        color: #777;
      }
      &:hover {
        background: #17a2b8;
        color: white;
        p,
        .head > span {
          color: white;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .sectionHead {
    grid-gap: 1.5rem;
    h2 {
      font-size: 2.25rem;
    }
  }
  .jobHistory {
    .jobs {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 570px) {
  .jobHistory {
    .jobs {
      .item {
        padding: 1.25rem;
        .head {
          grid-template-columns: 1fr;
          .buttonAnim {
            margin-top: 1.25rem;
            width: min-content;
            grid-row: 3/4;
            grid-column: 1/2;
          }
        }
      }
    }
  }
}
