@import "variables";

.banner {
  height: 953px;
  display: grid;
  grid-template-columns: 1fr 0.35fr;
  position: relative;
  align-items: flex-end;
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 50vw;
    background: $lightBlue;
    z-index: -1;
    left: 83%;
  }
  .texts {
    padding: 1rem;
    align-self: center;
    display: grid;
    grid-gap: 1.5rem;
    .chatBubble {
      background: $lightBlue;
      width: max-content;
      color: white;
      font-size: 1rem;
      padding: 1.2rem;
      border-radius: 0.5rem 0.5rem 0 0.5rem;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -15px;
        width: 0;
        height: 0;
        border-left: 26px solid transparent;
        border-right: 0px solid transparent;
        border-top: 24px solid $lightBlue;
      }
    }
    h1 {
      font-size: 3.7rem;
    }
    h3 {
      font-size: 2rem;
      font-weight: 400;
      margin-right: 10rem;
      color: #777;
      .red {
        color: $red;
      }
      .cyan {
        color: $lightBlue;
      }
    }
    button {
      margin-top: 1.5rem;
      width: max-content;
    }
  }
  .imgContainer {
    img {
      position: relative;
      left: 160px;
    }
  }
  .brands {
    margin: 0 1rem;
    position: absolute;
    background: white;
    width: calc(100% - 2rem);
    transform: translateY(50%);
    display: grid;
    padding: 3.5rem 0;
    border-radius: 10rem;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .wrapper {
      opacity: 0.5;
      .items {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        pointer-events: none;
      }
    }
  }
  margin-bottom: 10rem;
}

@media (max-width: 1480px) {
  .banner {
    .imgContainer {
      img {
        left: 0;
        max-width: 27rem;
      }
    }
  }
}
@media (max-width: 1200px) {
  .banner {
    .imgContainer {
      img {
        max-width: 20rem;
      }
    }
  }
}
@media (max-width: 990px) {
  .banner {
    .imgContainer {
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .banner {
    max-width: 100vw;
    height: 650px;
    grid-template-columns: 1fr;
    &::after {
      left: 95%;
    }
    .texts {
      h1 {
        font-size: 2.75rem;
      }
      h3 {
        font-size: 1.5rem;
        margin-right: 0;
      }
    }
    .brands {
      padding: 2rem 0;
      margin: 0 3rem;
      width: calc(100% - 6rem);
    }
  }
}
@media (max-width: 540px) {
  .banner {
    &::after {
      display: none;
    }
  }
}
