@import "variables";

.about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 6rem;
  padding: 3rem 1rem;
  .content {
    h2 {
      font-size: 3.3rem;
    }
    .paragraphs {
      display: grid;
      grid-gap: 2rem;
      line-height: 1.8em;
      color: #777;
      font-size: 0.95rem;
    }
    display: grid;
    grid-gap: 2rem;
    .actions {
      display: flex;
      grid-gap: 1rem;
    }
  }
}

@media (max-width: 990px) {
  .about {
    grid-template-columns: 1fr;
    padding-bottom: 3rem;
    .content {
      h2 {
        font-size: 2.25rem;
      }
      grid-gap: 1.5rem;
    }
    .imgContainer {
      display: none;
    }
  }
}
