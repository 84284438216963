@import "variables";

.skills {
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-gap: 2rem;
  align-items: center;
  .texts {
    h2 {
      font-size: 3.3rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.9rem;
      color: #777;
    }
  }
  .skillBars {
    display: grid;
    grid-gap: 1.75rem;
    h3 {
      margin-bottom: 0.5rem;
    }
    .outline {
      border: 1px solid rgba(0, 0, 0, 0.25);
      padding: 4px 7px;
      border-radius: 2rem;
      .fill {
        height: 7px;
        background: $lightBlue;
        position: relative;
        &::after {
          position: absolute;
          top: -4px;
          right: 0;
          content: "";
          display: block;
          width: 1px;
          height: 1.5rem;
          background: $lightBlue;
        }
        &:before {
          position: absolute;
          content: attr(data-value);
          display: block;
          bottom: -1.5rem;
          right: 5px;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .skills {
    .texts {
      h2 {
        font-size: 2.25rem;
      }
    }
    grid-template-columns: 1fr;
  }
}
