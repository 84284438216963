.App {
  max-width: 1140px;
  margin: auto;
  position: relative;
}

@media (max-width: 1200px) {
  .App {
    max-width: 960px;
  }
}
@media (max-width: 990px) {
  .App {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .App {
    width: 540px;
    max-width: 100vw;
  }
}
